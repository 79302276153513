<template>
    <div>
        <h3>Schedule Editor</h3>
        <hr>
        <b-button @click="addSchedule" variant="outline-success">
            <b-icon icon="calendar-plus"></b-icon> Add
        </b-button>
        <p></p>
        <div class="text-center mb-3"
             style="max-width: 60rem"
        >
            <b-table striped hover responsive="true" :items="items" :fields="fields">
                <template #cell(status)="row">
                    <b-icon-cloud-check v-if="items[row.index].stat"></b-icon-cloud-check>
                    <b-icon-cloud-slash v-else></b-icon-cloud-slash>
                </template>
                <template #cell(edit)="row">
                    <b-icon-pencil-fill @click="editItem(row.index)"></b-icon-pencil-fill>
                </template>
                <template #cell(duplicate)="row">
                    <b-icon-stickies-fill @click="dupItem(row.index)"></b-icon-stickies-fill>
                </template>
                <template #cell(delete)="row">
                    <b-icon-trash-fill @click="delItem(row.index)"></b-icon-trash-fill>
                </template>
            </b-table>
            <hr>
            <b-button @click="saveSchedule" variant="outline-primary">
                <b-icon icon="cloud-arrow-up"></b-icon> Save
            </b-button>
            <b-modal
                id="edit-sched"
                ref="modal"
                centered
                style="min-width: 30rem"
                :title="addEditTitle"
                @show="resetEditModal"
                @hidden="resetEditModal"
                @ok="handleEditOk"
            >
                <template #modal-footer>
                    <b-container>
                        <b-row cols="5">
                            <b-col cols=""></b-col>
                            <b-button
                                variant="success"
                                @click="handleEditOk"
                            >
                                Save
                            </b-button>
                            <b-col></b-col>
                            <b-button
                                variant="danger"
                                @click="handleEditCancel"
                            >
                                Cancel
                            </b-button>
                        </b-row>
                    </b-container>
                </template>
                <b-form ref="form" class="text-center" @submit.stop.prevent="handleEditSubmit">
                    <b-form-group>
                        <b-form-checkbox-group v-if="dayCheckEnabled"
                            v-model="selDays"
                            :options="dayChecks"
                            class="mb-3"
                            value-field="item"
                            text-field="name">
                        </b-form-checkbox-group>
                        <b-container v-if="dayCheckEnabled">
                            <b-row cols="3">
                                <b-col>
                                    <b-button @click="freqPreset(1)">Everyday</b-button>
                                </b-col>
                                <b-col>
                                    <b-button @click="freqPreset(2)">Weekdays</b-button>
                                </b-col>
                                <b-col>
                                    <b-button @click="freqPreset(3)">Weekends</b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                        <hr>
<!--                        <b-container v-if="isTankless">-->
<!--                            <b-row cols="6">-->
<!--                                <b-col>-->
<!--                                    <label style="max-width: 5rem">Type:</label>-->
<!--                                </b-col>-->
<!--                                <b-col cols="4">-->
<!--                                    <b-form-select v-model="selType" :options="typeList"></b-form-select>-->
<!--                                </b-col>-->
<!--                            </b-row>-->
<!--                            <hr>-->
<!--                        </b-container>-->
                        <b-container>
                            <b-row cols="6">
                                <b-col>
                                    <label style="max-width: 5rem">When:</label>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-select v-model="selWhen" v-on:change="onWhenChange" :options="whenList"></b-form-select>
                                </b-col>
                                <b-col cols="5" v-if="selWhen===2">
                                    <b-form-input id="type-time" type="time" v-model="whenDate"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-container>
                        <b-container v-if="!dayCheckEnabled">
                            <hr v-if="intSchedEnabled">
                            <b-row cols="6">
                                <b-col cols="6" v-if="intSchedEnabled">
                                    Start Time
                                    <b-form-input id="type-time" type="time" v-model="whenIntStart"></b-form-input>
                                </b-col>
                                <b-col cols="6" v-if="intSchedEnabled">
                                    End Time
                                    <b-form-input id="type-time" type="time" v-model="whenIntEnd"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-container>
                        <hr>
                        <b-container v-if="dayCheckEnabled">
                            <b-row cols="6">
                                <b-col>
                                    <label>Offset:</label>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-select :disabled="offDisable" v-model="selOffset" :options="offList"></b-form-select>
                                </b-col>
                                <b-col v-if="selOffset > 0">
                                    <b-form-input v-model="offsetTime"></b-form-input>
                                </b-col>
                                <b-col v-if="selOffset > 0">
                                    <label>Min(s)</label>
                                </b-col>
                            </b-row>
                        </b-container>
                        <b-container v-if="!dayCheckEnabled">
                            <b-row cols="3">
                                <b-col cols="4">
                                    <label>Interval Mode:</label>
                                </b-col>
                                <b-col cols="6">
                                    <b-form-select v-model="selIntMode" v-on:change="onIntModeChange" :options="intModeList"></b-form-select>
                                </b-col>
                            </b-row>
                            <b-row cols="4" v-if="selIntMode===1" style="margin-top: 10px">
                                <b-col cols="4">
                                    <label>Dynamic Mode:</label>
                                </b-col>
                                <b-col cols="6">
                                    <b-form-select v-model="selDynMode" v-on:change="onDynModeChange" :options="dynModeList"></b-form-select>
                                </b-col>
                            </b-row>
                        </b-container>
                        <b-container v-if="tempSetEnabled">
                            <hr>
                            <b-row cols="3">
                                <b-col>
                                    <label>Temperature:</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="tempF"></b-form-input>
                                </b-col>
                                <b-col>
                                    <label>F</label>
                                </b-col>
                            </b-row>
                        </b-container>
                        <hr>
                        <b-container>
                            <b-row cols="3">
                                <b-col>
                                    <label>Duration:</label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="durTime"></b-form-input>
                                </b-col>
                                <b-col>
                                    <label>Sec(s)</label>
                                </b-col>
                            </b-row>
                            <b-row cols="3" style="margin-top: 10px" v-if="!dayCheckEnabled">
                                <b-col>
                                    <label>Every: </label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="intTime"></b-form-input>
                                </b-col>
                                <b-col>
                                    <label>Min(s)</label>
                                </b-col>
                            </b-row>
                            <b-row cols="3" style="margin-top: 10px" v-if="numTimeEnabled">
                                <b-col>
                                    <label>Num Times: </label>
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="numTimes"></b-form-input>
                                </b-col>
                                <b-col>
                                    <label></label>
                                </b-col>
                            </b-row>
                        </b-container>
                        <div v-if="numZones===2">
                            <hr>
                            <b-form-checkbox-group
                                v-model="selZones"
                                :options="zoneChecks"
                                value-field="item"
                                text-field="name">
                            </b-form-checkbox-group>
                        </div>
                    </b-form-group>
                </b-form>
            </b-modal>
        </div>
    </div>
</template>

<script>
import eTypes from "@/enumTypes";

export default {
    name: "ScheduleEditor",
    data() {
        return {
            devId: null,
            items: [],
            fields: [
                'status',
                'type',
                'frequency',
                'offset',
                'when',
                'duration',
                'zone',
                'edit',
                'duplicate',
                'delete'
            ],
            dayCheckEnabled: true,
            intSchedEnabled: false,
            numTimeEnabled: false,
            tempSetEnabled: false,
            tempF: 85,
            selItem: 0,
            selType: 1,
            typeList: [
                {value: 1, text: "Spray"},
                {value: 4, text: "Clean"},
                {value: 5, text: "Purge"}
            ],
            addEdit: 0,
            addEditTitle: "Add",
            selDays: [],
            dayChecks: [
                {item: 0, name: "Su"},
                {item: 1, name: "Mo"},
                {item: 2, name: "Tu"},
                {item: 3, name: "We"},
                {item: 4, name: "Th"},
                {item: 5, name: "Fr"},
                {item: 6, name: "Sa"}
            ],
            selZones: [0],
            zoneChecks: [],
            selWhen: 0,
            whenList: [
                {value: 0, text: "Sunrise"},
                {value: 1, text: "Sunset"},
                {value: 2, text: "Custom"},
                {value: 3, text: "Interval"}
            ],
            whenDate: '12:00',
            whenIntStart: '12:00',
            whenIntEnd: '13:00',
            intTime: 45,
            numTimes: 10,
            selOffset: 0,
            offList: [
                {value: 0, text: "At"},
                {value: 1, text: "Before"},
                {value: 2, text: "After"},
            ],
            selIntMode: 0,
            intModeList: [
                {value: 0, text: "Schedule"},
                {value: 1, text: "Dynamic"}
            ],
            selDynMode: 0,
            dynModeList: [
                {value: 0, text: "Sunrise to Sunset"},
                {value: 1, text: "Sunrise"},
                {value: 2, text: "Sunset"},
                {value: 3, text: "Temperature Above"},
                {value: 4, text: "Temperature Below"}
            ],
            offDisable: null,
            offsetTime: 30,
            durTime: 25,
            numZones: 1,
            schedRef: null
        }
    },
    created() {
        this.devId = this.$route.params.id;
        this.schedRef = this.$store.getters.deviceSched.slice();
        this.zoneChecks = [];
        this.numZones = this.$store.getters.deviceCfg.numZones;
        if (this.numZones === 1) {
            this.zoneChecks.push({item: 0, name: "Zone 1", disabled: true });
        }
        if (this.numZones === 2) {
            this.zoneChecks.push({item: 0, name: "Zone 1", disabled: false });
            this.zoneChecks.push({item: 1, name: "Zone 2", disabled: false });
        }
        this.updateSched();
    },
    methods: {
        saveSchedule() {
            let sch = [];
            let changeCount = 0;
            for (let i = 0; i < this.schedRef.length; i++) {
                let s = {};
                s.time = this.schedRef[i].time;
                s.endTime = this.schedRef[i].endTime;
                s.useTemp = this.schedRef[i].useTemp;
                s.tempF = this.schedRef[i].tempF;
                s.intMode = this.schedRef[i].intMode;
                s.dynMode = this.schedRef[i].dynMode;
                s.numTimes = this.schedRef[i].numTimes;
                s.freq = this.schedRef[i].freq;
                s.eType = this.schedRef[i].eType;
                s.duration = this.schedRef[i].duration;
                s.interval = this.schedRef[i].interval;
                s.gmtOffset = this.schedRef[i].gmtOffset;
                s.startOffset = this.schedRef[i].startOffset;
                s.startFlag = this.schedRef[i].startFlag;
                s.daysFlag = this.schedRef[i].daysFlag;
                s.zone = this.schedRef[i].zone;

                sch.push(s);
                if (this.schedRef[i].stat === false) {
                    changeCount++;
                }
            }
            if (changeCount > 0 || this.schedRef.length !== this.$store.getters.deviceSched.length) {
                this.$store.dispatch('setDeviceSchedule', {id: this.devId, sch: sch}).then(() => {
                    this.$store.commit('storeDeviceSched', sch);
                    this.$router.back();
                });
            } else {
                this.$router.back();
            }
        },
        addSchedule() {
            this.addEdit = 0;
            this.selDays = [0, 1, 2, 3, 4, 5, 6];
            this.addEditTitle = "Add";
            this.$refs['modal'].show();
        },
        onWhenChange() {
            if (this.selWhen === 2) {
                this.selOffset = 0;
                this.offDisable = true;
                this.dayCheckEnabled = true;
                this.intSchedEnabled = false;
                this.numTimeEnabled = false;
            } else if (this.selWhen === 0 || this.selWhen === 1){
                this.offDisable = null;
                this.dayCheckEnabled = true;
                this.intSchedEnabled = false;
                this.numTimeEnabled = false;
            }
            else if (this.selWhen === 3) {
                this.dayCheckEnabled = false;
                this.intSchedEnabled = true;
                this.numTimeEnabled = false;
                this.selIntMode = 0;
                this.selDynMode = 0;
            }
        },
        onIntModeChange() {
          if (this.selIntMode === 0) {
              this.intSchedEnabled = true;
              this.numTimeEnabled = false;
              this.tempSetEnabled = false;
          }
          else if (this.selIntMode === 1) {
              if (this.selDynMode < 3)
                  this.intSchedEnabled = false;
              else
                  this.intSchedEnabled = true;

              if (this.selDynMode > 0)
                  this.numTimeEnabled = true;
              else
                  this.numTimeEnabled = false;

              if (this.selDynMode > 2) {
                  this.tempSetEnabled = true;
              }
          }

        },
        onDynModeChange() {
          if (this.selDynMode === 0) {
              this.numTimeEnabled = false;
          }
          else {
              this.numTimeEnabled = true;
          }

          if (this.selDynMode > 2) {
              this.intSchedEnabled = true;
              this.tempSetEnabled = true;
          }
          else {
              this.intSchedEnabled = false;
              this.tempSetEnabled = false;
          }


        },
        freqPreset(presetNum) {
          if (presetNum === 1) {
              this.selDays = [0, 1, 2, 3, 4, 5, 6];
          }
          else if (presetNum === 2) {
              this.selDays = [1, 2, 3, 4, 5];
          }
          else if (presetNum === 3) {
              this.selDays = [0,6];
          }
        },
        configureEditDialog(schType)
        {
            if (schType === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNRISE ||
            schType === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNSET) {
                this.offDisable = null;
                this.dayCheckEnabled = true;
                this.intSchedEnabled = false;
                this.numTimeEnabled = false;
                this.tempSetEnabled = false;
            }
            else if (schType === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CUSTOM) {
                this.selOffset = 0;
                this.offDisable = true;
                this.dayCheckEnabled = true;
                this.intSchedEnabled = false;
                this.numTimeEnabled = false;
            }
            else if (schType === eTypes.enums.Event_Start_Time.EVENT_START_TIME_INTERVAL) {
                this.dayCheckEnabled = false;
                this.intSchedEnabled = true;
                this.numTimeEnabled = false;
                this.onDynModeChange();
                this.onIntModeChange();
                if (this.selDynMode < 3) {
                    this.intSchedEnabled = false;
                }
                if (this.selIntMode === 0)
                    this.intSchedEnabled = true;

            }
        },
        editItem(itemIndex) {
            //console.log(itemIndex);
            this.selItem = itemIndex;
            this.addEdit = 1;
            this.addEditTitle = "Edit";
            const curSch = this.schedRef[this.selItem];
            this.selType = curSch.eType;
            this.selDays = [];
            if ((curSch.daysFlag & eTypes.enums.Days.SUNDAY) === eTypes.enums.Days.SUNDAY)
                this.selDays.push(0);
            if ((curSch.daysFlag & eTypes.enums.Days.MONDAY) === eTypes.enums.Days.MONDAY)
                this.selDays.push(1);
            if ((curSch.daysFlag & eTypes.enums.Days.TUESDAY) === eTypes.enums.Days.TUESDAY)
                this.selDays.push(2);
            if ((curSch.daysFlag & eTypes.enums.Days.WEDNESDAY) === eTypes.enums.Days.WEDNESDAY)
                this.selDays.push(3);
            if ((curSch.daysFlag & eTypes.enums.Days.THURSDAY) === eTypes.enums.Days.THURSDAY)
                this.selDays.push(4);
            if ((curSch.daysFlag & eTypes.enums.Days.FRIDAY) === eTypes.enums.Days.FRIDAY)
                this.selDays.push(5);
            if ((curSch.daysFlag & eTypes.enums.Days.SATURDAY) === eTypes.enums.Days.SATURDAY)
                this.selDays.push(6);

            this.selOffset = 0;
            this.offDisable = null;
            if (curSch.startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CUSTOM) {
                this.whenDate = this.moment(this.getLocalOffset(curSch.time, curSch.gmtOffset) * 1000).format("HH:mm");
                this.offDisable = true;
                this.selWhen = 2;
            }
            else if (curSch.startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_INTERVAL) {
                this.selWhen = 3;
                this.intTime = curSch.interval;
                this.whenIntStart = this.moment(this.getLocalOffset(curSch.time, curSch.gmtOffset) * 1000).format("HH:mm");
                this.whenIntEnd = this.moment(this.getLocalOffset(curSch.endTime, curSch.gmtOffset) * 1000).format("HH:mm");
                this.selIntMode = curSch.intMode - 1;
                if (curSch.intMode === eTypes.enums.Event_Interval_Mode.EVENT_INTERVAL_MODE_DYNAMIC) {
                    this.selDynMode = curSch.dynMode - 1;
                    if (curSch.dynMode === eTypes.enums.Event_Dynamic_Mode.EVENT_DYNAMIC_MODE_TEMP_ABOVE ||
                        curSch.dynMode === eTypes.enums.Event_Dynamic_Mode.EVENT_DYNAMIC_MODE_TEMP_BELOW) {
                        this.tempF = curSch.tempF;
                    }
                }
                else {
                    this.dynMode = 0;
                }
                this.intTime = curSch.interval;
                this.numTimes = curSch.numTimes;
            }
            else if (curSch.startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNRISE) {
                this.selWhen = 0
            }
            else if (curSch.startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNSET) {
                this.selWhen = 1;
            }
            if (curSch.startOffset !== 0) {
                if (curSch.startOffset < 0) {
                    this.selOffset = 1;
                    this.offsetTime = Math.abs(curSch.startOffset);
                }
                else {
                    this.selOffset = 2;
                    this.offsetTime = curSch.startOffset;
                }
            }

            if (curSch.zone === 1)
                this.selZones = [0];
            else if (curSch.zone === 2)
                this.selZones = [1];
            else if (curSch.zone === 3)
                this.selZones = [0, 1];

            this.durTime = curSch.duration;
            this.configureEditDialog(curSch.startFlag);
            this.$refs['modal'].show();
        },
        dupItem(itemIndex) {
            let dupEntry = {
                time: this.schedRef[itemIndex].time,
                endTime: this.schedRef[itemIndex].endTime,
                useTemp: this.schedRef[itemIndex].useTemp,
                tempF: this.schedRef[itemIndex].tempF,
                intMode: this.schedRef[itemIndex].intMode,
                dynMode: this.schedRef[itemIndex].dynMode,
                numTimes: this.schedRef[itemIndex].numTimes,
                freq: this.schedRef[itemIndex].freq,
                eType: this.schedRef[itemIndex].eType,
                duration: this.schedRef[itemIndex].duration,
                interval: this.schedRef[itemIndex].interval,
                gmtOffset: this.schedRef[itemIndex].gmtOffset,
                startOffset: this.schedRef[itemIndex].startOffset,
                startFlag: this.schedRef[itemIndex].startFlag,
                daysFlag: this.schedRef[itemIndex].daysFlag,
                zone: this.schedRef[itemIndex].zone,
                stat: false
            }
            this.schedRef.push(dupEntry);
            this.updateSched();
        },
        delItem(itemIndex) {
            this.schedRef.splice(itemIndex, 1);
            this.updateSched();
        },
        getLocalOffset(timestamp, altOffset) {
            const geoData = this.$store.getters.deviceGeo;
            let totalOffset = geoData.gmtOffset;
            let orgDst = this.moment(timestamp * 1000).isDST();
            let curDst = this.moment().isDST();
            let useLocal = false;
            if (Math.abs(Math.abs(altOffset) - Math.abs(geoData.gmtOffset)) > 1) {
                altOffset = totalOffset;
                useLocal = true;
            }
            if (altOffset !== 0) {
                if (altOffset !== geoData.gmtOffset) {
                    totalOffset = geoData.gmtOffset - (geoData.gmtOffset - altOffset);
                }
            }
            if (orgDst !== curDst) {
                if (!orgDst && curDst)
                    totalOffset += 1;
                else if (orgDst && !curDst)
                    totalOffset -= 1;
            }
            if (useLocal) {
                timestamp += ((totalOffset * 60) * 60);
                let mn = this.moment(timestamp * 1000);
                let offsetTime = mn.valueOf();
                return offsetTime / 1000;
            }
            else {
                let mn = this.moment(timestamp * 1000).utcOffset(totalOffset * 60);
                let offsetTime = mn.valueOf();
                return offsetTime / 1000;
            }
        },
        resetEditModal() {

        },
        // eslint-disable-next-line no-unused-vars
        handleEditOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            if (this.selZones.length === 0) {
                alert("Must Select At Least One Zone!");
                return;
            }
            // Trigger submit handler
            this.handleEditSubmit();
        },
        // eslint-disable-next-line no-unused-vars
        handleEditCancel(bvModalEvt) {
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('edit-sched')
            });
        },
        handleEditSubmit() {
            const sysGmtOffset = this.$store.getters.deviceGeo.gmtOffset;
            let editIdx = this.selItem;
            if (this.addEdit === 0) { // Add new Entry
                let newEntry = {
                    time: Date.now() / 1000,
                    endTime: Date.now() / 1000,
                    useTemp: false,
                    tempF: 85,
                    intMode: 0,
                    dynMode: 0,
                    numTimes: 10,
                    freq: 4,
                    eType: 1,
                    duration: 60,
                    interval: 45,
                    gmtOffset: sysGmtOffset,
                    startOffset: 0,
                    startFlag: 2,
                    daysFlag: 127,
                    zone: 1
                }
                this.schedRef.push(newEntry);
                editIdx = this.schedRef.length - 1;
            }
            this.schedRef[editIdx].stat = false;
            this.schedRef[editIdx].gmtOffset = sysGmtOffset;
            let daysFlag = 0;
            for (let i = 0; i < this.selDays.length; i++) {
                daysFlag += (1 << this.selDays[i]);
            }
            this.schedRef[editIdx].daysFlag = daysFlag;
            if (this.selOffset !== 0) {
                if (this.selOffset === 1) {
                    this.schedRef[editIdx].startOffset = -this.offsetTime;
                } else {
                    this.schedRef[editIdx].startOffset = this.offsetTime;
                }
            }

            this.schedRef[editIdx].eType = this.selType;

            if (this.selWhen === 0) { // Sunrise
                this.schedRef[editIdx].time = this.$store.getters.deviceGeo.sunrise;
                this.schedRef[editIdx].startFlag = 0
            }
            else if (this.selWhen === 1) { // Sunset
                this.schedRef[editIdx].time = this.$store.getters.deviceGeo.sunset;
                this.schedRef[editIdx].startFlag = 1
            }
            else if (this.selWhen === 2) { // Custom
                this.schedRef[editIdx].time = parseInt(this.moment(this.whenDate, 'HH:mm').format('X'));
                this.schedRef[editIdx].startOffset = 0;
                this.schedRef[editIdx].startFlag = 2
            }
            else if (this.selWhen === 3) { // Interval
                this.schedRef[editIdx].time = parseInt(this.moment(this.whenIntStart, 'HH:mm').format('X'));
                this.schedRef[editIdx].endTime = parseInt(this.moment(this.whenIntEnd, 'HH:mm').format('X'));
                this.schedRef[editIdx].interval = this.intTime;
                this.schedRef[editIdx].intMode = this.selIntMode + 1;
                if (this.schedRef[editIdx].intMode === eTypes.enums.Event_Interval_Mode.EVENT_INTERVAL_MODE_DYNAMIC) {
                    this.schedRef[editIdx].dynMode = this.selDynMode + 1;
                }
                else {
                    this.schedRef[editIdx].dynMode = eTypes.enums.Event_Dynamic_Mode.EVENT_DYNAMIC_MODE_UNDEFINED;
                }
                this.schedRef[editIdx].numTimes = this.numTimes;
                this.schedRef[editIdx].freq = eTypes.enums.Frequency.EVENT_FREQ_INTERVAL;
                this.schedRef[editIdx].eType = eTypes.enums.Event_Type.EVENT_TYPE_SPRAY;

                if (this.schedRef[editIdx].dynMode === eTypes.enums.Event_Dynamic_Mode.EVENT_DYNAMIC_MODE_TEMP_ABOVE ||
                this.schedRef[editIdx].dynMode === eTypes.enums.Event_Dynamic_Mode.EVENT_DYNAMIC_MODE_TEMP_BELOW) {
                    this.schedRef[editIdx].useTemp = true;
                    this.schedRef[editIdx].tempF = this.tempF;
                }
                else {
                    this.schedRef[editIdx].useTemp = false;
                    this.schedRef[editIdx].tempF = 85;
                }
                this.schedRef[editIdx].startOffset = 0;
                this.schedRef[editIdx].startFlag = eTypes.enums.Event_Start_Time.EVENT_START_TIME_INTERVAL;
            }

            this.schedRef[editIdx].zone = 0;
            for (let i = 0; i < this.selZones.length; i++) {
                this.schedRef[editIdx].zone += (this.selZones[i] + 1)
            }
            this.schedRef[editIdx].duration = this.durTime;
            this.updateSched();

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('edit-sched')
            });
        },
        updateSched() {
            this.items = [];
            const sch = this.schedRef;
            let cnt = 0;
            for (let i = 0; i < sch.length; i++) {
                let sItem = {};

                if (sch[i].eType === 1)
                    sItem.type = "Spray";
                else if (sch[i].eType === 4) {
                    sItem.type = "Clean";
                    continue;
                }
                else if (sch[i].eType === 5) {
                    sItem.type = "Purge";
                    continue;
                }

                if (cnt % 2 === 0) {
                    sItem._rowVariant = 'warning';
                }
                // else {
                //     sItem._rowVariant = 'secondary';
                // }
                if (sch[i].daysFlag === eTypes.enums.Days.WEEKENDS_ONLY) {
                    sItem.frequency = "Weekends";
                }
                else if (sch[i].daysFlag === eTypes.enums.Days.WEEKDAYS_ONLY) {
                    sItem.frequency = "Weekdays";
                }
                else if (sch[i].daysFlag === eTypes.enums.Days.EVERYDAY) {
                    sItem.frequency = "Everyday";
                }
                else {
                    sItem.frequency = "Custom";
                }
                if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CUSTOM) {
                    sItem.when = this.moment(this.getLocalOffset(sch[i].time, sch[i].gmtOffset) * 1000).format("h:mm A");
                }
                else if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_INTERVAL) {
                    sItem.when = "Int " + sch[i].interval + " Mins";
                }
                else if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNRISE) {
                    sItem.when = "Sunrise";
                }
                else if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNSET) {
                    sItem.when = "Sunset";
                }

                // eslint-disable-next-line no-prototype-builtins
                if (sch[i].hasOwnProperty('stat')) {
                    sItem.stat = sch[i].stat;
                } else {
                    sItem.stat = true;
                }

                if (sch[i].startOffset !== 0) {
                    if (sch[i].startOffset > 0) {
                        sItem.offset = sch[i].startOffset + " min after";
                    } else {
                        sItem.offset = -sch[i].startOffset + " min before";
                    }
                } else {
                    sItem.offset = "At";
                }

                sItem.duration = sch[i].duration + " sec";
                if (sch[i].zone === 3)
                    sItem.zone = "1 & 2";
                else
                    sItem.zone = sch[i].zone;

                this.items.push(sItem);
                cnt++;
            }
        },
        // zoneFormat(value) {
        //     if (value === null)
        //         return "1";
        //     if (value === 3)
        //         return "1 & 2";
        //     else
        //         return value.toString();
        // }
    },
    computed: {
        isTankless() {
            return this.$store.getters.deviceCfg.tankless;
        }
    }
}
</script>

<style scoped>

</style>
