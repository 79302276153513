<template>
  <div>
    <b-col align-self="center">
      <b-card
          class="mb-2"

      >
          <b-card>
              <label style="color:#5b620f;">Mode: </label>
              <b-button class="cmd-buttons" variant="success" v-b-modal.change-mode>{{devMode}}</b-button>
          </b-card>
          <div v-if="isRunning">Status: Running</div>
          <div v-else>Status: Not Running</div>
        <div>
          <b-button class="cmd-buttons" variant="success" v-b-modal.run-mist>Run Mist</b-button>
          <b-button class="cmd-buttons" variant="danger" v-b-modal.stop-mist>Stop Mist</b-button>
<!--          <b-button class="cmd-buttons" variant="warning" v-b-modal.test-mist>Test System</b-button>-->
          <b-button v-if="isSelfManaged" class="cmd-buttons" :disabled="refillDisabled" variant="primary" v-b-modal.refill-tank>Refill System</b-button>
        </div>
          <b-modal
              id="change-mode"
              ref="modal"
              centered
              title="System Mode"
              @show="resetModeModal"
              @hidden="resetModeModal"
              @ok="handleModeOk"
          >
              <form ref="form" @submit.stop.prevent="handleModeSubmit">
                  <b-form-select v-model="modeSelected" :options="modeOptions"></b-form-select>
              </form>
          </b-modal>
          <b-modal
              id="run-mist"
              ref="modal"
              centered
              title="Manual Run Time"
              @show="resetRunModal"
              @hidden="resetRunModal"
              @ok="handleRunOk"
          >
              <form ref="form" @submit.stop.prevent="handleRunSubmit">
                  <b-form-group
                      label="Duration (Sec)"
                      label-for="run-input"
                      :state="runState"
                  >
                      <b-form-input
                          id="run-input"
                          v-model="runTime"
                          :state="runState"
                          required
                      ></b-form-input>
                  </b-form-group>
                  <b-form-group
                      label="Interval (Min)"
                      label-for="run-intMin"
                      :state="runState"
                      v-if="devMode==='Local'"
                  >
                      <b-form-input
                          id="run-intMin"
                          v-model="runIntTime"
                          :state="runState"
                          required
                      ></b-form-input>
                  </b-form-group>
                  <b-form-group
                      label="Num Times"
                      label-for="run-times"
                      :state="runState"
                      v-if="devMode==='Local'"
                  >
                      <b-form-input
                          id="run-times"
                          v-model="runNumTimes"
                          :state="runState"
                          required
                      ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                      <div v-if="numZones===2" class="text-center">
                          <hr>
                          <b-form-checkbox-group
                              v-model="selZones"
                              :options="zoneChecks"
                              value-field="item"
                              text-field="name">
                          </b-form-checkbox-group>
                      </div>
                  </b-form-group>
              </form>
          </b-modal>
          <b-modal
              id="stop-mist"
              ref="modal"
              centered
              title="Stop Mist"
              @show="resetStopModal"
              @hidden="resetStopModal"
              @ok="handleStopOk"
          >
              <form ref="form" @submit="handleStopSubmit">
                  <b-form-group
                      label="Please Confirm"
                  >
                  </b-form-group>
              </form>
          </b-modal>
          <b-modal
              id="test-mist"
              ref="modal"
              centered
              title="Test System"
              @show="resetTestModal"
              @hidden="resetTestModal"
              @ok="handleTestOk"
          >
              <form ref="form" @submit.stop.prevent="handleTestSubmit">
                  <b-form-group
                      label="Minutes"
                      label-for="test-input"
                      :state="testState"
                  >
                      <b-form-input
                          id="test-input"
                          v-model="testTime"
                          :state="testState"
                          required
                      ></b-form-input>
                  </b-form-group>
              </form>
          </b-modal>
          <b-modal
              id="refill-tank"
              ref="modal"
              hide-backdrop
              centered
              content-class="shadow"
              title="Amount Added"
              @show="resetRefillModal"
              @hidden="resetRefillModal"
              @ok="handleRefillOk"
          >
              <form ref="form" @submit.stop.prevent="handleRefillSubmit">
                  <b-form-group
                      label="Gallons"
                      label-for="refill-input"
                      :state="refillState"
                  >
                      <b-form-input
                          id="refill-input"
                          v-model="refillAmt"
                          :state="refillState"
                          required
                      ></b-form-input>
                  </b-form-group>
              </form>
          </b-modal>
        <b-container v-if="!isTankless">
          <b-row cols="1">
              <b-card class="text-center">
                  <b-row>
                      <b-col>
                        <h6>{{calcTankPercent.toFixed(2)}}%</h6>
                      </b-col>
                  </b-row>
                  <b-row>
                      <b-col>
                          <b-progress :value="calcTankPercent"></b-progress>
                      </b-col>
                  </b-row>
              </b-card>
              <b-card class="text-center">
                <b-row>
                    <b-col>
                        <b-card>
                            <h3 class="text-center">{{calcTankDaysLeft}}</h3>
                        </b-card>
                    </b-col>
                    <b-col cols="4" class="text-center">
                        Days Until Empty
                    </b-col>
                </b-row>
              </b-card>
          </b-row>
        </b-container>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import roles from '@/roles';
  export default {
    name: "Command",
    props: ['id', 'devtype'],
      data() {
        return {
            runTime: this.$store.getters.deviceCfg.mistDefault,
            runState: null,
            stopMist: false,
            testTime: 5,
            runIntTime: 45,
            runNumTimes: 10,
            deviceType: '',
            testState: null,
            refillAmt: this.$store.getters.deviceCfg.tankSize,
            refillState: null,
            refillDisabled: true,
            socket: null,
            modeSelected: 0,
            statusInterval: null,
            selZones: [0],
            zoneChecks: [],
            modeOptions: [
                {value: 0, text: 'Auto'},
                {value: 1, text: 'Local'},
                {value: 2, text: 'Off'}
                ]
        }
      },
      created() {
        this.deviceType = this.$props.devtype;
        this.isDisabled();
          if (this.numZones === 2) {
              this.zoneChecks.push({item: 0, name: "Zone 1", disabled: false });
              this.zoneChecks.push({item: 1, name: "Zone 2", disabled: false });
          }
          this.statusInterval = setInterval(() => {
              this.$store.dispatch('sendSockMessage', {text: "qStat"});
          }, 5000);
      },
      beforeDestroy() {
          if (this.statusInterval) {
              clearInterval(this.statusInterval);
          }
      },
      computed: {
        numZones() {
          return this.$store.getters.deviceCfg.numZones;
        },
        isRunning() {
            return this.$store.getters.isSpraying;
        },
        calcTankPercent() {
            const tankAmt = this.$store.getters.deviceInfo.tank;
            const tankSize = this.$store.getters.deviceCfg.tankSize;
            return (tankAmt / tankSize) * 100.0;
        },
        calcTankDaysLeft() {
            const tankAmt = this.$store.getters.deviceInfo.tank;
            const weekAvg = this.$store.getters.deviceInfo.week;
            if (weekAvg > 0.0)
                return Math.trunc(tankAmt / (weekAvg / 7.0));
            else
                return Math.trunc(tankAmt / 0.8);
        },
        devMode() {
            if (isNaN(this.$store.getters.getDeviceSysMode)) {
                return this.modeOptions[0].text;
            }
            else {
                return this.modeOptions[this.$store.getters.getDeviceSysMode].text;
            }
        },
        isTankless() {
            if (this.$store.getters.deviceCfg !== null) {
                if ("tankless" in this.$store.getters.deviceCfg) {
                    return this.$store.getters.deviceCfg.tankless;
                }
                else
                    return false;
            }
            else
                return false;
        },
        isSelfManaged() {
          if (this.$store.getters.deviceSys.sysType === 0)
            return true;
          else
            return false;
        }
      },
      methods: {
        isDisabled() {
          if (this.$store.getters.role >= roles.roles.technician || this.$store.getters.deviceSys.sysType === 0)
              this.refillDisabled = false;
          else
              this.refillDisabled = true;
        },
        checkRunFormValidity() {
            const valid = this.$refs.form.checkValidity();
            let intCheck = false;
            if (!isNaN(parseInt(this.runTime))) {
                if (parseInt(this.runTime) <= 90 && parseInt(this.runTime) > 0)
                    intCheck = true;
            }
            if (valid && intCheck) {
                this.runState = true;
                return true;
            } else {
                this.runState = false;
                return false;
            }
        },
        resetRunModal() {
            this.runTime = this.$store.getters.deviceCfg.mistDefault;
            this.runState = null
        },
        handleRunOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            if (this.selZones.length === 0) {
                alert("Must Select At Least One Zone!");
                return;
            }
            // Trigger submit handler
            if (this.$store.getters.isDeviceOnline) {
                this.handleRunSubmit();
            }
            else {
                this.$bvToast.toast('Device Appears To Be Offline, Run Command Won\'t Work Right Now', {
                    title: 'Device Command',
                    variant: 'danger',
                    solid: true
                });
            }
        },
        handleRunSubmit() {
            // Exit when the form isn't valid
            if (!this.checkRunFormValidity()) {
                return
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('run-mist')
            });
            if (this.devMode === "Auto") {
                let runZone = 1;
                if (this.numZones === 2) {
                    runZone = 0;
                    for (let i = 0; i < this.selZones.length; i++) {
                        runZone += (this.selZones[i] + 1)
                    }
                }
                this.$store.dispatch('sendSockMessage', {
                    text: "runSpray",
                    user: this.$store.getters.user,
                    dur: this.runTime,
                    zone: runZone
                });
            }
            else if (this.devMode === "Local") {
                this.$store.dispatch('sendSockMessage', {
                    text: "runSpray",
                    user: this.$store.getters.user,
                    dur: this.runTime,
                    intv: this.runIntTime,
                    numt: this.runNumTimes,
                    zone: 1
                });
            }

        },
        resetStopModal() {
            this.stopMist = false;
        },
        // eslint-disable-next-line no-unused-vars
        handleStopOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.$store.getters.isDeviceOnline) {
                this.handleStopSubmit();
            }
            else {
                this.$bvToast.toast('Device Appears To Be Offline, Stop Command Won\'t Work Right Now', {
                    title: 'Device Command',
                    variant: 'danger',
                    solid: true
                });
            }
        },
        handleStopSubmit() {
            this.stopMist = true;

            this.$store.dispatch('sendSockMessage', {
                text: "stopSpray"
            });

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('stop-mist')
            });
        },
        checkTestFormValidity() {
            const valid = this.$refs.form.checkValidity();
            let intCheck = false;
            if (!isNaN(parseInt(this.testTime))) {
                if (parseInt(this.testTime) <= 10 && parseInt(this.runTime) > 0)
                    intCheck = true;
            }
            if (valid && intCheck) {
                this.testState = true;
                return true;
            } else {
                this.testState = false;
                return false;
            }
        },
        resetTestModal() {
            this.testTime = 5;
            this.testState = null
        },
        handleTestOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleTestSubmit()
        },
        handleTestSubmit() {
            // Exit when the form isn't valid
            if (!this.checkTestFormValidity()) {
                return
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('test-mist')
            });
            // TODO: Send Test Mist Command and update Tank Info

        },
        checkRefillFormValidity() {
            const valid = this.$refs.form.checkValidity();
            let intCheck = false;
            if (!isNaN(parseInt(this.refillAmt))) {
                if (parseInt(this.refillAmt) <= this.$store.getters.deviceCfg.tankSize && parseInt(this.refillAmt) > 0)
                    intCheck = true;
            }
            if (valid && intCheck) {
                this.refillState = true;
                return true;
            } else {
                this.refillState = false;
                return false;
            }
        },
        resetRefillModal() {
            this.refillAmt = this.$store.getters.deviceCfg.tankSize;
            this.refillState = null
        },
        handleRefillOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleRefillSubmit()
        },
        handleRefillSubmit() {
            // Exit when the form isn't valid
            if (!this.checkRefillFormValidity()) {
                return
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('refill-tank')
            });
            this.$store.dispatch('sendSockMessage', {
              text: "runRefill",
              user: this.$store.getters.user,
              gal: this.refillAmt
            });
            // Delay and then get Tank Info
          setTimeout(() => {
            this.$store.dispatch('getDeviceInfo', this.id);
          }, 3000);
        },
        resetModeModal() {
            this.modeSelected = this.$store.getters.getDeviceSysMode;
        },
        // eslint-disable-next-line no-unused-vars
        handleModeOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.$store.getters.isDeviceOnline) {
                this.handleModeSubmit();
            }
            else {
                this.$bvToast.toast('Device Appears To Be Offline, Mode Command Won\'t Work Right Now', {
                    title: 'Device Command',
                    variant: 'danger',
                    solid: true
                });
            }
        },
        handleModeSubmit() {
            this.$store.dispatch('sendSockMessage', {text: 'sMode', mode: this.modeSelected  })

          // Hide the modal manually
          this.$nextTick(() => {
              this.$bvModal.hide('change-mode')
          });
        },
    }
  }
</script>

<style scoped>
  .cmd-buttons {
    margin: 5px;
    min-width: 120px;
    max-width: 140px;
  }
  .text-center {
      text-align: center;
      margin: 3px;
  }

</style>
