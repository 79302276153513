const e = {};

const Event_Start_Time = {
    EVENT_START_TIME_SUNRISE: 0,
    EVENT_START_TIME_SUNSET: 1,
    EVENT_START_TIME_CUSTOM: 2,
    EVENT_START_TIME_INTERVAL: 3,
    EVENT_START_TIME_CONTINUOUS: 4,
    EVENT_START_TIME_CYCLE: 5
}
e.Event_Start_Time = Event_Start_Time;

const Activity_Status =
{
    SM_ACTIVITY_STATUS_NONE: 0,
    SM_ACTIVITY_STATUS_LOW: 1,
    SM_ACTIVITY_STATUS_REFILL: 2,
    SM_ACTIVITY_STATUS_NORMAL: 3,
    SM_ACTIVITY_STATUS_CLOG: 4,
    SM_ACTIVITY_STATUS_SKIPPED: 5,
    SM_ACTIVITY_STATUS_LEAK: 6,
    SM_ACTIVITY_STATUS_TEST: 7,
    SM_ACTIVITY_STATUS_NO_FLOW: 8
}
e.Activity_Status = Activity_Status;

const Event_Interval_Mode =
{
    EVENT_INTERVAL_MODE_UNDEFINED: 0,
    EVENT_INTERVAL_MODE_SCHEDULE: 1,
    EVENT_INTERVAL_MODE_DYNAMIC: 2
}
e.Event_Interval_Mode = Event_Interval_Mode;

const Event_Dynamic_Mode =
{
    EVENT_DYNAMIC_MODE_UNDEFINED: 0,
    EVENT_DYNAMIC_MODE_SUNRISE_TO_SUNSET: 1,
    EVENT_DYNAMIC_MODE_SUNRISE: 2,
    EVENT_DYNAMIC_MODE_SUNSET: 3,
    EVENT_DYNAMIC_MODE_TEMP_ABOVE: 4,
    EVENT_DYNAMIC_MODE_TEMP_BELOW: 5
}
e.Event_Dynamic_Mode = Event_Dynamic_Mode;

const Frequency =
{
    EVENT_FREQ_UNDEFINED: 0,
    EVENT_FREQ_INTERVAL: 1,
    EVENT_FREQ_MINUTELY: 2,
    EVENT_FREQ_HOURLY: 3,
    EVENT_FREQ_DAILY: 4,
    EVENT_FREQ_MONTHDAY: 5
};
e.Frequency = Frequency;

const Event_Type =
{
    EVENT_TYPE_UNDEFINED: 0,
    EVENT_TYPE_SPRAY: 1,
    EVENT_TYPE_REFILL: 2,
    EVENT_TYPE_TEST: 3,
    EVENT_TYPE_CLEAN: 4,
    EVENT_TYPE_PURGE: 5
};
e.Event_Type = Event_Type;

const Event_Status =
{
    EVENT_STATUS_UNDEFINED: 0,
    EVENT_STATUS_WAIT: 1,
    EVENT_STATUS_FIRST: 2,
    EVENT_STATUS_DONE: 3,
    EVENT_STATUS_QUEUE: 4,
    EVENT_STATUS_START: 5,
    EVENT_STATUS_STOP: 6,
    EVENT_STATUS_RUNNING: 7,
    EVENT_STATUS_AGITATING: 8
};
e.Event_Status = Event_Status;

const Event_Save_Status =
{
    EVENT_SAVE_DEFAULT: 0,
    EVENT_SAVE_SYNCED: 1,
    EVENT_SAVE_CHANGED: 2,
    EVENT_SAVE_SAVED: 3
};
e.Event_Save_Status = Event_Save_Status;

const Event_Flag =
{
    EVENT_FLAG_ENABLED: 0,
    EVENT_FLAG_DISABLED_NEXT: 1,
    EVENT_FLAG_DISABLED: 2
};
e.Event_Flag = Event_Flag;

const Days =
{
    SUNDAY: 1,
    MONDAY: 2,
    TUESDAY: 4,
    WEDNESDAY: 8,
    THURSDAY: 16,
    FRIDAY: 32,
    SATURDAY: 64,
    WEEKDAYS_ONLY: 62,
    WEEKENDS_ONLY: 65,
    EVERYDAY: 127
}
e.Days = Days;

exports.enums = e;

