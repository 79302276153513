<template>
  <div>
    <h3>Device Details -
        <b-badge v-if="isDevOnline" variant="success">Online</b-badge>
        <b-badge v-else variant="danger">Offline</b-badge>
    </h3>
    <p>{{location}}</p>
    <hr>
    <div id="app">
      <div class="page" v-if="!isSynced">
        <b-spinner class="spinner" variant="primary" key="primary"></b-spinner>
      </div>
      <b-container v-if="isSynced">
        <b-row
            cols="1"
            cols-sm="1"
            cols-md="2"
            cols-lg="3"
            align-v="start"
            align-h="between"
        >
            <next-spray :id="devId"></next-spray>
            <command-mod :id="devId" v-if="devType === 'InsectControl'"></command-mod>
            <cmd-mod-cool-mist :id="devId" v-if="devType === 'CoolMist'"></cmd-mod-cool-mist>
            <activity-graph v-if="devType === 'InsectControl' && isNotAssociate" :id="devId"></activity-graph>
            <activity-log v-if="isNotAssociate" :id="devId" :devtype="devType"></activity-log>
            <spray-schedule v-if="devType === 'InsectControl' && isNotAssociate" :id="devId"></spray-schedule>
            <spray-sch-cool-mist v-if="devType === 'CoolMist' && isNotAssociate" :id="devId"></spray-sch-cool-mist>
            <suspend-schedule v-if="isNotAssociate" :id="devId"></suspend-schedule>
            <geo-data :id="devId"></geo-data>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import NextSpray from "@/components/modules/NextSpray";
import Command from "@/components/modules/Command";
import CommandCoolMist from "@/components/modules/CommandCoolMist";
import ActivityLog from "@/components/modules/ActivityLog";
import SpraySchedule from "@/components/modules/SpraySchedule";
import SpraySchCoolMist from "@/components/modules/SpraySchCoolMist";
import SuspendSchedule from "@/components/modules/SuspendSchedule";
import GeoData from "@/components/modules/GeoData";
import ActivityGraph from "@/components/modules/ActivityGraph";
//import roles from '@/roles';
import Vue from "vue";

export default {
  name: "DeviceDetails",
  components: {
    nextSpray: NextSpray,
    commandMod: Command,
    cmdModCoolMist: CommandCoolMist,
    activityLog: ActivityLog,
    spraySchedule: SpraySchedule,
    spraySchCoolMist: SpraySchCoolMist,
    suspendSchedule: SuspendSchedule,
    geoData: GeoData,
    activityGraph: ActivityGraph
  },
  data() {
    return {
      devId: null,
      refreshInterval: null,
      pingInterval: null,
      location: ""
    }
  },
  computed: {
    isSynced() {
      return this.$store.getters.isDeviceSync;
    },
      isDevOnline() {
        return this.$store.getters.isDeviceOnline;
      },
      devType () {
          return this.$store.getters.getCurDevType;
      },
      isNotAssociate() {
        // if (this.$store.getters.role === roles.roles.guestUser)
        //     return true;
        // else
            return true;
      }
  },
  created() {
    this.devId = this.$route.params.id;
    Vue.http.get('ugeo/loc/street?id=' + this.devId).then(response => {
      return response.json();
    }).then(res => {
      this.location = res.street;
    }).catch(err => {
      console.log(err);
    });
    this.$store.dispatch('getDeviceInfo', this.devId);
    this.$store.dispatch('getDeviceCfg', this.devId);
    this.$store.dispatch('getDeviceSys', this.devId);
    //this.$store.dispatch('getDeviceUsers', this.devId);
    this.$store.dispatch('getCompList', this.devId);
    this.$store.dispatch('getDeviceGeo', this.devId);
    this.$store.dispatch('getDeviceSchedule', this.devId);
    this.$store.dispatch('getDeviceSuspend', this.devId);
    this.refreshInterval = setInterval(() => {
      this.$store.dispatch('checkDeviceSync');
      if (this.$store.getters.isDeviceSync) {
        this.$store.dispatch('joinDeviceRoom', this.devId);
        clearInterval(this.refreshInterval);
      }
    }, 1000);
    this.pingInterval = setInterval(() => {
        if (!this.$store.getters.isDeviceOnline) {
            this.$store.dispatch('sendSockMessage', {text: 'ping'});
        }
        else {
            let curTime = Date.now();
            let lastPing = this.$store.getters.getLastDevicePingTime;
            let elapsedTime = Math.round(Math.abs((curTime - lastPing) / 1000));
            if (elapsedTime > 59) {
                this.$store.dispatch('sendSockMessage', {text: 'ping'});
            }
            if (elapsedTime > 69) {
                this.$store.commit('setDeviceOnlineStatus', false);
            }
        }
    }, 10000);
  },
    beforeDestroy() {
      this.$store.dispatch('leaveDeviceRoom', this.devId);
      this.$store.commit('setDeviceOnlineStatus', false);
      this.$store.commit('setActiveDevice', '9x9x9x');
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }
    },
    destroyed() {

  }
}
</script>

<style scoped>
#app {
  text-align: center;
  color: #2c3e50;
}
.page {
  /*position: absolute;*/
  /*background: rgba(0,0,0,0.3);*/
  /*z-index: 25;*/
  /*width: 100%;*/
  /*height: 100%;*/
}
.spinner {
  position: center;
  /*top: 100%;*/
  margin-top: 10rem;
}
</style>
