<template>
    <div class="mb-2"
         style="max-width: 60rem"
    >
        <b-col>
            <b-card class="md-3">
                <h6 class="font-weight-bold">Schedule</h6>
                <hr>
                <b-table striped hover responsive="true" no-border-collapse :items="items" :fields="fields">

                </b-table>
                <hr>
                <b-button @click="editSchedule" variant="outline-primary">
                    <b-icon icon="pencil"></b-icon> Edit
                </b-button>
            </b-card>
        </b-col>
    </div>
</template>

<script>
import eTypes from '@/enumTypes';

export default {
    name: "SpraySchedule",
    props: ['id'],
    data () {
        return {
            items: [],
            fields: [
                'days',
                "time",
                "duration"
            ],
            refreshSched: null,
            syncStatDate: Date.now() / 1000
        }
    },
    created() {
        //console.log("Sched List Created");
        this.updateSched();
        this.refreshSched = setInterval(() => {
            this.$store.dispatch('getDeviceSchedStatus', this.id).then(() => {
                if (this.$store.getters.getSchedSyncCode !== 1) {
                    if (this.$store.getters.getSchedSyncTime !== this.syncStatDate) {
                        this.syncStatDate = this.$store.getters.getSchedSyncTime;
                        this.$store.dispatch('getDeviceSchedule', this.id).then(() => {
                            this.updateSched();
                        });
                    }
                }
            })
        }, 29000);
    },
    destroyed() {
        if (this.refreshSched) {
            clearInterval(this.refreshSched);
        }
    },
    methods: {
        editSchedule() {
          this.$router.push('/schedule/edit/' + this.id);
        },
        getLocalOffset(timestamp, altOffset) {
            const geoData = this.$store.getters.deviceGeo;
            let totalOffset = geoData.gmtOffset;
            let orgDst = this.moment(timestamp * 1000).isDST();
            let curDst = this.moment().isDST();
            let useLocal = false;
            if (Math.abs(Math.abs(altOffset) - Math.abs(geoData.gmtOffset)) > 1) {
                altOffset = totalOffset;
                useLocal = true;
            }
            if (altOffset !== 0) {
                if (altOffset !== geoData.gmtOffset) {
                    totalOffset = geoData.gmtOffset - (geoData.gmtOffset - altOffset);
                }
            }
            if (orgDst !== curDst) {
                if (!orgDst && curDst)
                    totalOffset += 1;
                else if (orgDst && !curDst)
                    totalOffset -= 1;
            }
            if (useLocal) {
                timestamp += ((totalOffset * 60) * 60);
                let mn = this.moment(timestamp * 1000);
                let offsetTime = mn.valueOf();
                return offsetTime / 1000;
            }
            else {
                let mn = this.moment(timestamp * 1000).utcOffset(totalOffset * 60);
                let offsetTime = mn.valueOf();
                return offsetTime / 1000;
            }
        },
        updateSched() {
            this.items = [];
            const sch = this.$store.getters.deviceSched;
            let cnt = 0;
            for (let i = 0; i < sch.length; i++) {
                let sItem = {};

                if (sch[i].eType === 4) {
                    continue;
                }
                else if (sch[i].eType === 5) {
                    continue;
                }

                if (cnt % 2 === 0) {
                    sItem._rowVariant = 'warning';
                }
                // else {
                //     sItem._rowVariant = 'secondary';
                // }
                if (sch[i].daysFlag === eTypes.enums.Days.WEEKENDS_ONLY) {
                    sItem.days = "Weekends";
                }
                else if (sch[i].daysFlag === eTypes.enums.Days.WEEKDAYS_ONLY) {
                    sItem.days = "Weekdays";
                }
                else if (sch[i].daysFlag === eTypes.enums.Days.EVERYDAY) {
                    sItem.days = "Everyday";
                }
                else {
                    sItem.days = "Custom";
                }

                if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CUSTOM) {
                    sItem.time = this.moment(this.getLocalOffset(sch[i].time, sch[i].gmtOffset) * 1000).format("h:mm A");
                }
                else if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_INTERVAL) {
                    sItem.time = "Int " + sch[i].interval + " Mins";
                }
                else if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNRISE) {
                    sItem.time = "Sunrise";
                }
                else if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNSET) {
                    sItem.time = "Sunset";
                }

                if (sch[i].startOffset !== 0) {
                    if (sch[i].startOffset > 0) {
                        sItem.time = sch[i].startOffset + " min after " + sItem.time;
                    } else {
                        sItem.time = -sch[i].startOffset + " min before " + sItem.time;
                    }
                }

                sItem.duration = sch[i].duration + " sec";

                this.items.push(sItem);
                cnt++;
            }
        }
    }
}
</script>

<style scoped>
table.table tr.table-foobar td {
    background-color: yellow ;
}
</style>
